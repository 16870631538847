#tag-input > div > .MuiInputBase-root {
  display: inline-block;
}

.tab-buttons {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.tab-buttons button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
}

.tab-buttons .active {
  background-color: #4d4d4d;
  color: white;
  font-weight: bold;
  border-bottom: 2px solid #4d4d4d;
}

.tab-content {
  padding: 10px 0;
}

.domains-table {
  vertical-align: middle;
  border-collapse: separate;
  border-spacing: 0;
  border: none;
  min-width: 800px;
  background: #fff;
  padding: 0;
  border-radius: 20px;
}

.domains-table thead th,
.domains-table tbody tr td {
  font-weight: 700;
  color: 343434;
  padding: 15px 0;
  text-align: center;
  vertical-align: middle;
}

.domains-table tbody tr:nth-child(odd) {
  background-color: #ececed;
}

.domains-table tbody tr td {
  font-weight: normal;
}
