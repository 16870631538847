.app {
  .sidebar {
    overflow-y: auto;
    top: 0;
    bottom: 0;
    position: fixed;
    padding: 10px 10px 0 10px;
    background-color: #ececed;

    .spacer {
      flex: 1;
    }
  }

  .sidebar-nav {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    padding-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 70px;
  }

  h1 {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #cdcdcd;
  }

  .dashboard {
    position: relative;
    z-index: 1;
  }
  
  .dashboard::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px 5px 5px 5px;
    color: white;
    transform: scaleY(0);
    transform-origin: bottom center;
    background-color: #9aa0a4;
    background-image: linear-gradient(135deg, #9aa0a4 0%, #414243 100%);
    z-index: -1;
    transition: transform 0.5s;
}
.dashboard:hover::after {
    transform: scaleY(1);
}

// .dashboard:hover {
//     --p: 100%;
//     background-color: #9aa0a4;
//     background-image: linear-gradient(135deg, #9aa0a4 0%, #414243 100%);
//    }
}

// hide scroll bar
.app .sidebar::-webkit-scrollbar {
  width: 0;
  height: 0;
}