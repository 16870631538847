.login {
  h1 {
    text-align: center;
  }

  form {
    padding: 10px 20px;
    max-width: 300px;
    margin: auto;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 3px 3px rgb(0 0 0 / 3%);
  }

  .submit {
    margin-top: 10px;
  }
}
